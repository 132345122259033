import React from 'react'
import Helmet from 'react-helmet'
import Tutoring from '../Tutoring'
import PropTypes from 'prop-types'

const TutoringPageTemplate = (props) => {
  const { title, meta_title, meta_description, tutoring } = props

  return (
    <div>
      <Helmet>
        <title>{meta_title}</title>
        <meta name='description' content={meta_description} />
      </Helmet>
      <section className='hero has-background is-bold is-medium'>
        <img className='hero-background' src='/img/tutoringheader.jpg' />
        <div className='hero-body'>
          <div className='container'>
            <div className='columns'>
              <div className='column is-10 is-offset-1'>
                <div className='section'>
                  <h1 className='title is-1'>
                    {title}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='section section--gradient'>
        <div className='container'>
          <div className='section'>
            <div className='columns'>
              <div className='column is-10 is-offset-1'>
                <div className='content'>                  
                  <div className='is-size-5'>
                    <div>                  
                        The CAPSA provides quality and personalized one-on-one tutoring service to K-12 students 
                        during the MCPS academic school year. Offered in person, these tutoring sessions are held 
                        once per week and cover various subject areas to address the educational and personal needs 
                        of the students. Our tutors and program leaders are trained student volunteers who are eager 
                        to help other students and give back to the community.
                    </div>
                    <ul>
                      <li><span class='has-text-weight-bold'>Tutoring session location:</span> In person at the Robert Frost Middle School (9201 Scott Dr, Rockville, MD 20850)</li>
                      <li><span class='has-text-weight-bold'>Tutoring session dates/times:</span> 10 AM to 12 PM on Saturdays (<a href="/calendar">see calendar here </a>)</li>
                      <li><span class='has-text-weight-bold'>Tutoring session cost:</span> All tutoring sessions are free of charge. Yet each tutee is asked to contribute a $40 administrative fee to help cover CAPSA's operational expenses. The fee will be collected at the first tutoring session.</li>
                    </ul>
                    <div>Interested in becoming a tutee or tutor? Click on the button below to fill out the application form!</div>
                  </div>
                  <div class="mt-6 container is-fluid">
                    <a class="mr-5 button is-info has-text-weight-bold is-size-4" href="https://airtable.com/appBpI4Wc5vT3DH5R/shrTIiDHXS9e6ZXno">Become a Tutee</a>
                    <a class="button is-info has-text-weight-bold is-size-4" href="https://airtable.com/appBpI4Wc5vT3DH5R/shrCj8cfTwnIAnBBr">Become a Tutor</a>
                  </div>
                </div>
                <Tutoring data={tutoring.plans} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
TutoringPageTemplate.propTypes = {
  title: PropTypes.string,
  meta_title: PropTypes.string,
  meta_description: PropTypes.string,

  pricing: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
    plans: PropTypes.array,
  }),
}

export default TutoringPageTemplate
